<template>
  <div class="ranking">
    <div class="content">
      <ModTit :moreText="$t('Home.SeeMore')" :more="{ path: '/mine/ranking' }">
        <template v-slot:left>
          <h3>
            <span class="h3">{{
              module.menuJsonObj
                ? module.menuJsonObj[langNamesEnum[languageActive]]
                : module.title
            }}</span>
            <span class="ranking-reload">
              <a-tooltip
                :title="
                  module.menuJsonObj
                    ? module.menuJsonObj[langNamesEnum[languageActive]]
                    : module.title + $t('Home.reload')
                "
              >
                <SyncOutlined
                  :class="['reload-icon', { rotate: isRotate }]"
                  @click="rankingChange('refresh')"
                />
              </a-tooltip>
            </span>
          </h3>
        </template>
      </ModTit>
      <div class="card" v-if="!rankingLoading">
        <swiper
          :modules="modules"
          :loop="true"
          :loopedSlides="3"
          slidesPerView="auto"
          :navigation="true"
          :centeredSlides="true"
          :watchSlidesProgress="true"
          @setTransition="setTransition"
          @progress="progress"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(list, index) in rankingData" :key="index">
            <div class="ranks">
              <div class="ranks-title">
                <div class="box box-0" v-if="index == 0">
                  {{ $t("Home.WeekLeaderboard") }}
                </div>
                <div class="box box-1" v-if="index == 1">
                  {{ $t("Home.MonthLeaderboard") }}
                </div>
                <div class="box box-2" v-if="index == 2">
                  {{ $t("Home.AllRankings") }}
                </div>
                <div class="box box-3" v-if="index == 3">
                  {{ $t("Home.TodayLeaderboard") }}
                </div>
              </div>
              <div class="top">
                <template v-for="(item, i) in list" :key="i">
                  <div class="top-item" :class="'top-item-' + i" v-if="i < 3">
                    <div class="top-item-box">
                      <div class="avatar">
                        <img
                          :src="
                            item.portrait ||
                            require(`@/assets/image/rank_man.png`)
                          "
                          alt="portrait"
                        />
                      </div>
                      <div class="name" v-if="item.userId">
                        <OpenData type="userName" :openid="item.realName" />
                      </div>
                      <div class="name" v-else>
                        {{ $t("Home.WaitingInVain")
                        }}<!-- 虚位以待 -->
                      </div>
                      <div class="department" v-if="item.userId">
                        <OpenData
                          type="departmentName"
                          :openid="item.departmentName"
                        />
                      </div>
                      <div class="time" v-if="item.userId">
                        {{
                          module.rankType == 2 &&
                          companyInfo.menu.includes("integral")
                            ? item.totalIntegral
                            : formatTime(item.totalTime)
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="list">
                <template v-for="(item, i) in list" :key="i">
                  <div class="list-item" v-if="i >= 3">
                    <div class="index">{{ i + 1 }}</div>
                    <div class="avatar">
                      <img
                        :src="
                          item.portrait ||
                          require(`@/assets/image/rank_man.png`)
                        "
                        alt="portrait"
                      />
                    </div>
                    <div class="name">
                      <OpenData type="userName" :openid="item.realName" />
                    </div>
                    <div class="department">
                      <OpenData
                        type="departmentName"
                        :openid="item.departmentName"
                      />
                    </div>
                    <div class="time">
                      <template
                        v-if="
                          module.rankType == 2 &&
                          companyInfo.menu.includes('integral')
                        "
                      >
                        <span style="color: #626463; font-weight: 400"
                          >{{ $t("integral.level_integral") }}:</span
                        >{{ item.totalIntegral }}
                      </template>
                      <template v-else>{{
                        formatTime(item.totalTime)
                      }}</template>
                    </div>
                  </div>
                </template>
                <div class="empty-box" v-if="list.length <= 3">
                  <a-empty
                    :image="require('@/assets/image/no_data_2.png')"
                    :image-style="{
                      height: '189px',
                    }"
                  >
                    <template #description>
                      <div style="color: #999; margin-top: 12px">
                        {{ $t("Pub_Lab_NoRank") }}
                      </div>
                    </template>
                  </a-empty>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { formatTime } from "@/utils/tools";
import { getAllRanking, getIntegralAllRanking } from "@/api/other";
import OpenData from "@/components/OpenData.vue";
import ModTit from "@/components/layout/ModTit.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
export default {
  name: "ranking",
  components: {
    OpenData,
    ModTit,
    Swiper,
    SwiperSlide,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const companyInfo = computed(() => store.getters.companyInfo);
    const rankingType = ["Home.LearningDurationRanking"]; // 学习时长排行榜
    const swiperActiveDate = ref(3); // 1全部 2今日 3本周 4本月
    const typeActive = ref(0);
    const changeType = (i) => {
      if (typeActive.value === i) return;
      typeActive.value = i;
    };
    const isRotate = ref(false);
    let action = getAllRanking;
    if (
      companyInfo.value.menu.includes("integral") &&
      props.module.rankType == 2
    ) {
      action = getIntegralAllRanking;
    }
    const rankingData = ref([]),
      rankingLoading = ref(true),
      getRankings = (type = "none") => {
        action({
          refresh: type == "refresh",
          date: swiperActiveDate.value,
        }).then((res) => {
          if (res.ret === 0) {
            let d = res.data;
            let list = [];
            list.push(d.weeklyRankings.ranks || []);
            list.push(d.monthlyRankings.ranks || []);
            list.push(d.overallRankings.ranks || []);
            list.push(d.dailyRankings.ranks || []);
            for (let i = 0; i < list.length; i++) {
              if (list[i].length == 0) list[i] = [{}, {}, {}];
              if (list[i].length == 1) list[i] = list[i].concat([{}, {}]);
              if (list[i].length == 2) list[i].push({});
            }
            rankingData.value = list;
          }
          rankingLoading.value = false;
        });
      },
      rankingChange = (type = "none") => {
        if (type == "refresh") {
          isRotate.value = !isRotate.value;
          getRankings(type);
          return;
        }
        getRankings(type);
      };
    getRankings();

    const setTransition = (swiper, transition) => {
      for (var i = 0; i < swiper.slides.length; i++) {
        var slide = swiper.slides.eq(i);
        slide.transition(transition);
      }
    };
    const progress = (progress) => {
      for (var i = 0; i < progress.slides.length; i++) {
        var slide = progress.slides.eq(i);
        var slideProgress = progress.slides[i].progress;
        var modify = 1;
        if (Math.abs(slideProgress) > 1) {
          modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
        }
        var translate = slideProgress * modify * 167 + "px";
        var scale = 1 - Math.abs(slideProgress) / 9;
        var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
        slide.transform("translateX(" + translate + ") scale(" + scale + ")");
        slide.css("zIndex", zIndex);
        slide.css("opacity", 1);
        if (Math.abs(slideProgress) > 3) {
          slide.css("opacity", 0);
        }
      }
    };
    const onSlideChange = (e) => {
      if (e.activeIndex == 5) {
        swiperActiveDate.value = 1;
      } else if (e.activeIndex == 2 || e.activeIndex == 6) {
        swiperActiveDate.value = 2;
      } else if (e.activeIndex == 3 || e.activeIndex == 7) {
        swiperActiveDate.value = 3;
      } else if (e.activeIndex == 4) {
        swiperActiveDate.value = 4;
      }
    };
    return {
      companyInfo,
      rankingType,
      typeActive,
      formatTime,
      changeType,
      isRotate,
      rankingLoading,
      rankingData,
      rankingChange,
      modules: [Navigation],
      setTransition,
      progress,
      onSlideChange,
    };
  },
};
</script>

<style lang="less" scoped>
.ranking {
  .content {
    .mixinWrap();
    .mod-tit {
      h3 {
        line-height: 34px;
        margin-bottom: 0;
        .mixinFlex(flex-start; center);
        .h3 {
          font-size: 24px;
          font-weight: 600;
        }
        .ranking-reload {
          margin-left: 12px;
          color: #666;
          vertical-align: middle;
          line-height: 16px;
          .reload-icon {
            font-size: 16px;
            transition: all 2s;
            &:hover {
              color: @color-theme;
            }
            &.rotate {
              transform: rotateZ(360deg);
            }
          }
        }
      }
    }
    .card {
      position: relative;
      ::v-deep(.swiper) {
        .swiper-slide {
          width: 531px;
          padding: 5px 0;
          .ranks {
            width: 531px;
            height: 658px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
            position: relative;
            padding: 12px;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.2);
              border-radius: 8px;
            }
            &-title {
              position: absolute;
              top: -3px;
              left: 0;
              right: 0;
              height: 43px;
              .mixinFlex(center);
              .box {
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 43px;
                padding: 0 5px;
                position: relative;
                &::before {
                  content: "";
                  width: 40px;
                  height: 43px;
                  position: absolute;
                  left: -38px;
                  background-size: 100% 100%;
                }
                &::after {
                  content: "";
                  width: 40px;
                  height: 43px;
                  position: absolute;
                  right: -38px;
                  background-size: 100% 100%;
                }
                &.box-0 {
                  background: linear-gradient(134deg, #ea4b84 0%, #e47ada 100%);
                  &::before {
                    background-image: url("../../assets/image/rang_top_l_1.png");
                  }
                  &::after {
                    background-image: url("../../assets/image/rang_top_r_1.png");
                  }
                }
                &.box-1 {
                  background: linear-gradient(134deg, #854bff 0%, #e47aba 100%);
                  &::before {
                    background-image: url("../../assets/image/rang_top_l_2.png");
                  }
                  &::after {
                    background-image: url("../../assets/image/rang_top_r_2.png");
                  }
                }
                &.box-2 {
                  background: linear-gradient(134deg, #0ccbcb 0%, #94daf0 100%);
                  &::before {
                    background-image: url("../../assets/image/rang_top_l_3.png");
                  }
                  &::after {
                    background-image: url("../../assets/image/rang_top_r_3.png");
                  }
                }
                &.box-3 {
                  background: linear-gradient(134deg, #26c7ae 0%, #7be185 100%);
                  &::before {
                    background-image: url("../../assets/image/rang_top_l_4.png");
                  }
                  &::after {
                    background-image: url("../../assets/image/rang_top_r_4.png");
                  }
                }
              }
            }
            .top {
              width: 428px;
              height: 222px;
              background-image: url("../../assets/image/rank123.png");
              background-size: 100% 100%;
              margin: 40px auto 22px;
              position: relative;
              &-item {
                width: 143px;
                height: 222px;
                position: absolute;
                &-box {
                  text-align: center;
                  .avatar {
                    .mixinImgWrap(46px, 46px);
                    border-radius: 50%;
                    display: inline-flex;
                  }
                  .name {
                    font-size: 14px;
                    .mixinEllipsis(20px);
                    text-align: center;
                    max-width: 133px;
                    margin: 40px auto 0;
                  }
                  .department {
                    font-size: 14px;
                    .mixinEllipsis(20px);
                    text-align: center;
                    max-width: 133px;
                    margin: 4px auto 0;
                  }
                  .time {
                    font-size: 14px;
                    .mixinEllipsis(20px);
                    text-align: center;
                    max-width: 133px;
                    margin: 4px auto 0;
                    font-weight: 600;
                  }
                }
                &.top-item-1 {
                  left: 1px;
                  .top-item-box {
                    padding-top: 37px;
                    color: #666;
                  }
                }
                &.top-item-0 {
                  left: 143px;
                  .top-item-box {
                    padding-top: 1px;
                    color: #ff9900;
                  }
                }
                &.top-item-2 {
                  left: 286px;
                  .top-item-box {
                    padding-top: 60px;
                    color: #e07d48;
                  }
                }
              }
            }
            .list {
              &-item {
                height: 50px;
                padding: 11px 16px;
                line-height: 40px;
                font-size: 14px;
                color: #626463;
                background-color: #fff;
                .mixinFlex(space-between; center);
                &:nth-child(odd) {
                  background-color: #fafafa;
                }
                .index {
                  width: 20px;
                }
                .avatar {
                  .mixinImgWrap(28px, 28px);
                  border-radius: 50%;
                }
                .name {
                  width: 130px;
                  .mixinEllipsis(28px);
                }
                .department {
                  width: calc(100% - 350px);
                  .mixinEllipsis(28px);
                }
                .time {
                  width: 130px;
                  text-align: right;
                  color: @color-theme;
                  font-weight: 600;
                }
              }
              .empty-box {
                padding-top: 90px;
                display: none;
              }
            }
          }
          &-active {
            .ranks {
              &::after {
                content: unset;
              }
            }
            .list {
              .empty-box {
                display: block !important;
              }
            }
          }
        }
        .swiper-button-prev {
          width: 48px;
          height: 48px;
          &::after {
            content: "";
            width: 48px;
            height: 48px;
            background-image: url("../../assets/image/swiper_prev_2.png");
            background-size: 100% 100%;
          }
          &:hover {
            &::after {
              background-image: url("../../assets/image/swiper_prev_1.png");
            }
          }
        }
        .swiper-button-next {
          width: 48px;
          height: 48px;
          &::after {
            content: "";
            width: 48px;
            height: 48px;
            background-image: url("../../assets/image/swiper_next_2.png");
            background-size: 100% 100%;
          }
          &:hover {
            &::after {
              background-image: url("../../assets/image/swiper_next_1.png");
            }
          }
        }
      }
    }
  }
}
</style>
